import React from 'react';
import ProductList from '../components/product_list';
import Layout from '../components/layout';
import { graphql } from "gatsby";
import Seo from '../components/seo';

const Catalog = ({ data }) => {
    const productList = data.allMarkdownRemark.edges;
    return (
        <Layout>
            <div className="w-screen flex flex-col inset-x-0 top-0">
                <ProductList list={productList}/>
            </div>
        </Layout>
    );
};

export const query = graphql`
{
  allMarkdownRemark(filter: {frontmatter: {slug: {ne: null}, activo: {eq: true}}}) {
    edges {
      node {
        frontmatter {
          title
          slug
          galeria
        }
      }
    }
  }
}
`

export default Catalog;

export function Head() {
  return(
    <Seo />
  );
}